import { registerApplication, start, navigateToUrl } from "single-spa";

//
if (location.href === window.location.origin + "/") {
    if (sessionStorage.getItem("jwt") === null) {
        navigateToUrl("/login");
    } else {
        navigateToUrl("/dashboard");
    }
}

registerApplication({
    name: "@aim-mf/navbar",
    app: () => System.import("@aim-mf/navbar"),
    activeWhen: [
        "/risk-management",
        "/dashboard",
        "/risk-edit",
        "/admin",
        "/control-self-assessment",
        "/task-management",
        "/alert-management",
        "/user-profile",
    ],
});

registerApplication({
    name: "@aim-mf/user-profile",
    app: () => System.import("@aim-mf/user-profile"),
    activeWhen: "/user-profile",
});

registerApplication({
    name: "@aim-mf/risk-management",
    app: () => System.import("@aim-mf/risk-management"),
    activeWhen: "/risk-management",
});

registerApplication({
    name: "@aim-mf/dashboard",
    app: () => System.import("@aim-mf/dashboard"),
    activeWhen: "/dashboard",
});

registerApplication({
    name: "@aim-mf/login",
    app: () => System.import("@aim-mf/login"),
    activeWhen: "/login",
});

registerApplication({
    name: "@aim-mf/risk-edit",
    app: () => System.import("@aim-mf/risk-edit"),
    activeWhen: "/risk-edit",
});

registerApplication({
    name: "@aim-mf/user-admin",
    app: () => System.import("@aim-mf/user-admin"),
    activeWhen: "/admin",
});

registerApplication({
    name: "@aim-mf/control-self-assessment",
    app: () => System.import("@aim-mf/control-self-assessment"),
    activeWhen: "/control-self-assessment",
});

registerApplication({
    name: "@aim-mf/task-management",
    app: () => System.import("@aim-mf/task-management"),
    activeWhen: "/task-management",
});

registerApplication({
    name: "@aim-mf/alert-management",
    app: () => System.import("@aim-mf/alert-management"),
    activeWhen: "/alert-management",
});

start();
